export default {
  "menu": {
    "navs": {
      "nav1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial & Corporate"])},
      "nav2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AVM"])},
      "nav3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Real Estate Agency"])},
      "nav4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts"])}
    }
  },
  "landing": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reopla by Sprengnetter"])},
    "hero_h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Property"])},
    "hero_h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Easy. Secure. Valuation."])},
    "hero_btn_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn More"])},
    "1st_stat": {
      "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+15M"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data behind\nour AVM"])}
    },
    "2nd_stat": {
      "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+200K"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Property Valuation Reports\nper Year"])}
    },
    "3rd_stat": {
      "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+580K"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certified Real Estate Sales Data\nabout last 24 months"])}
    },
    "map_section_big_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimate market-driven\nproperty values"])},
    "map_section_small_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced AVM - Automated Valuation Model"])},
    "left_blue_box": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial\n& Corporate"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["B2B-services for Companies\nin the Financial and Real Estate Industry"])},
      "btn_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solutions"])}
    },
    "right_blue_box": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Real Estate Agency"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services for Real Estate\nAgencies and Brokers"])},
      "btn_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solutions"])}
    },
    "financial_corporate": {
      "section_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial & Corporate"])},
      "1st_col": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Database"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More than 580.000 certified data supporting your valuations"])}
      },
      "2nd_col": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AVM"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest cutting-edge technologies such as Machine Learning, AI and more..."])}
      },
      "3rd_col": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API Integration"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can integrate our solutions into your systems quickly, easily\nand well-documented"])}
      }
    },
    "real_estate": {
      "section_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Real Estate Agency"])},
      "1st_col": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Database"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The most used valuation software by Real Estate Agents in Italy"])}
      },
      "2nd_col": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AVM"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The biggest and most complete Database of Sold properties in Italy"])}
      },
      "3rd_col": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chosen by"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More than 5.000 Real Estate Agents throughout Italy use our solutions daily"])}
      }
    },
    "avm_section": {
      "left_col": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AVM"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artificial Intelligence matched with knowledge of the real estate industry leads to great customer experience supported by numbers, data and facts."])}
      }
    },
    "claim_section": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make Real Estate Market transparent"])}
    },
    "partners": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For successful market players"])}
    }
  },
  "contact_form": {
    "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By entering your email, you agree to our Terms of Service and "])},
    "send_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "errors": {
      "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is required"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Must be a valid email address"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Must be a valid numeric phone"])},
      "privacyCheck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have to accept Privacy Policy"])}
    }
  },
  "toasts_messages": {
    "form_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please, check form fields and retry!"])},
    "form_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message sent!"])}
  },
  "footer": {
    "socials": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social"])}
    },
    "terms": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms & Conditions"])},
      "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
      "cookie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookie Policy"])}
    },
    "contacts": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts"])}
    },
    "copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2022 ® Made by Reopla S.r.l. - P. IVA 11302360018 - All rights reserved"])}
  }
}