import {createRouter, createWebHistory} from "vue-router";

const Router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/:lang",
      name: "Landing",
      component: () => import('./Landing'),
      // beforeEnter(to, from, next) {
      //   const lang = to.params.lang;
      //
      //   if (!["en", "it"].includes(lang)) return next("en");
      //   if (i18n.locale !== lang) {
      //     i18n.locale = lang;
      //   }
      //   return next();
      // }
    },
    {
      path: '/:catchAll(.*)',
      redirect: "/it"
    }
  ]
});

export default Router;