export default {
  "menu": {
    "navs": {
      "nav1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial & Corporate"])},
      "nav2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AVM"])},
      "nav3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agenzie Immobiliari"])},
      "nav4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contatti"])}
    }
  },
  "landing": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reopla by Sprengnetter"])},
    "hero_h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Real Estate"])},
    "hero_h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valutazioni. Veloci. Sicure."])},
    "hero_btn_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scopri di più"])},
    "1st_stat": {
      "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+15M"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["di Data Point\nalla base del modello AVM"])}
    },
    "2nd_stat": {
      "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+200K"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report di valutazione immobiliare\nogni anno"])}
    },
    "3rd_stat": {
      "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+580K"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dati sul Compravenduto Certificati\ninerenti gli ultimi 24 mesi"])}
    },
    "map_section_big_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valutazioni Immobiliari\nguidate dai valori di mercato"])},
    "map_section_small_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AVM - Modello di Valutazione Automatica"])},
    "left_blue_box": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial\n& Corporate"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servizi B2B dedicati alle imprese\ndel settore Finanziario e Immobiliare"])},
      "btn_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soluzioni"])}
    },
    "right_blue_box": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agenzie Immobiliari"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servizi dedicati ai professionisti\ndel settore Immobiliare"])},
      "btn_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soluzioni"])}
    },
    "financial_corporate": {
      "section_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial & Corporate"])},
      "1st_col": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banca Dati"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oltre 580.000 dati Certificati in Agenzia delle Entrate a supporto delle tue Valutazioni"])}
      },
      "2nd_col": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AVM"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultime tecnologie all'avanguardia\nquali Machine Learning e AI"])}
      },
      "3rd_col": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrazione API"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puoi integrare le nostre Soluzioni nei tuoi sistemi in modo rapido, semplice e ben documentato"])}
      }
    },
    "real_estate": {
      "section_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agenzie Immobiliari"])},
      "1st_col": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AgentPricing.com"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il software di Valutazione Immobiliare più utilizzato in Italia dagli Agenti Immobiliari"])}
      },
      "2nd_col": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AVM"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il più grande e il più completo Database del compravenduto esistente in Italia"])}
      },
      "3rd_col": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ci hanno scelto"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Più di 5.000 Agenti Immobiliari in tutta Italia utilizzano quotidianamente le nostre soluzioni"])}
      }
    },
    "avm_section": {
      "left_col": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AVM"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'Intelligenza Artificiale, unita alla conoscenza del settore immobiliare, porta a un'esperienza cliente eccezionale supportata da numeri, dati e fatti."])}
      }
    },
    "claim_section": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reopla by Sprengnetter"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendiamo trasparente il Mercato Immobiliare"])}
    },
    "partners": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizzano le nostre Soluzioni"])}
    }
  },
  "contact_form": {
    "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cellulare"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oggetto"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messaggio"])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spuntando questa casella confermi di aver letto e accettato la "])},
    "send_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invia"])},
    "errors": {
      "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campo obbligatorio"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devi inserire un indirizzo email corretto"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devi inserire un numero di cellulare valido"])},
      "privacyCheck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devi accettare la Privacy Policy"])}
    }
  },
  "toasts_messages": {
    "form_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controlla tutti i campi del form e riprova!"])},
    "form_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messaggio inviato!"])}
  },
  "footer": {
    "socials": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social"])}
    },
    "terms": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termini e Condizioni"])},
      "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informativa sulla Privacy"])},
      "cookie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookie Policy"])}
    },
    "contacts": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contatti"])}
    },
    "copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2022 ® Realizzato da Reopla S.r.l. - P. IVA 11302360018 - Tutti i diritti riservati"])}
  }
}