import {createApp} from 'vue/dist/vue.esm-bundler';

import Router from "./router";
import i18n from './i18n';

import axios from "axios";
import VueAxios from "vue-axios";

import Toast, {POSITION} from "vue-toastification";
import { VueReCaptcha } from 'vue-recaptcha-v3'
import "vue-toastification/dist/index.css";

import './styles/app.scss';

const app = createApp({});

const toastOptions = {
  position: POSITION.TOP_CENTER,
  maxToasts: 1,
};

app.use(Router);
app.use(i18n);
app.use(VueAxios, axios);
app.use(Toast, toastOptions);
app.use(VueReCaptcha, { siteKey: '6LeRyDIfAAAAADpiMgPttWICY5q1EPpigNoxgNr-' });

Router.isReady().then(() => {
  app.mount('#app');
})
